<template>
  <div class="flex flex-col">
    <span
      class="-mt-10 font-extrabold text-blue-500 
      items-center inline-flex cursor-pointer"
      @click="$emit('goBack', 'payment-method')"
    >
      <img :src="assets.icons.sm.back" class="mr-2" alt="Back" />
      Back
    </span>
    <h1 class="text-34 mt-2 font-black tracking-tight mb-2">
      {{ businessName | sentenceCase }}
    </h1>
    <p class="text-12 font-normal mb-4 text-marketplace-blue text-opacity-75">
      Pay back in a month.
    </p>

    <div class="flex justify-between items-center text-marketplace-blue mt-8">
      <h1 class="text-11 font-normal">Order Amount</h1>
      <h1 class="text-11 font-normal">
        {{ orderAmount | formatAmount }}
      </h1>
    </div>
    <div
      class="w-full mt-3 mb-3 border-0.7 bg-opacity-50 bg-marketplace-blue"
    ></div>
    <div class="flex justify-between items-center text-marketplace-blue mt-2">
      <h1 class="text-11 font-normal">Interest Rate</h1>
      <h1 class="text-11 font-normal">
        {{ interestRate }}
      </h1>
    </div>
    <div
      class="w-full mt-3 mb-3 border-0.7 border-opacity-50 bg-marketplace-blue"
    ></div>
    <div
      class="flex justify-between items-center text-marketplace-blue mt-2"
      v-if="!discountNotFound"
    >
      <div class="inline-flex items-center">
        <h1 class="text-11 font-normal">You save</h1>
        <div
          class="rounded-full text-8 text-white bg-marketplace-green 
          w-17 p-1 ml-3 flex justify-center items-center font-bold"
        >
          {{ discount }}% discount
        </div>
      </div>
      <h1 class="text-11 font-normal">
        {{ discountAmount | formatAmount }}
      </h1>
    </div>
    <div
      class="w-full mt-3 mb-3 border-0.7 border-opacity-50 bg-marketplace-blue"
      v-if="!discountNotFound"
    ></div>
    <div class="flex justify-between items-center text-marketplace-blue mt-2">
      <div class="inline-flex items-center">
        <h1 class="text-11 font-normal">Total repayment amount</h1>
        <div
          class="rounded-full text-8 text-white bg-purple-400 
          min-w-17 p-1 ml-3 flex justify-center items-center font-bold"
        >
          {{ billingDate }}
        </div>
      </div>
      <h1 class="text-11 font-normal">
        {{ orderAmount | formatAmount }}
      </h1>
    </div>
    <div
      class="w-full mt-3 mb-3 border-0.7 border-opacity-50 bg-marketplace-blue"
    ></div>

    <div class="mt-16 flex justify-center">
      <button
        class="btn btn-blue py-6 w-full"
        type="button"
        :disabled="loading"
        @click="confirmOrder"
      >
        Continue
      </button>
    </div>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      oneTimeSummary: this.$options.resource({}),
      loading: false
    };
  },
  computed: {
    businessName() {
      return this.$attrs?.vendor?.business_name;
    },
    order() {
      return this.$attrs?.order;
    },
    billingDate() {
      return this.$attrs?.billing_date;
    },
    orderAmount() {
      return this.order?.amount?.value;
    },
    summary() {
      return this.oneTimeSummary?.data;
    },
    discount() {
      return this.summary?.discount;
    },
    discountNotFound() {
      return !!this.discount <= 0;
    },
    discountAmount() {
      return this.summary?.discount_amount;
    },
    interestRate() {
      return this.summary?.interest_rate;
    },
    totalRepayment() {
      return this.summary?.total_repayment;
    }
  },
  beforeMount() {
    this.getOneTimePaymentSummary();
  },
  methods: {
    async getOneTimePaymentSummary() {
      this.oneTimeSummary.setLoading(true);
      await this.sendRequest(
        "personal.marketplace.paymentSummary.oneTimeSummary",
        {
          data: {
            amount: this.orderAmount
          },
          success: response => (this.oneTimeSummary.data = response.data.data),
          error: error => console.log(error)
        }
      );
      this.oneTimeSummary.setLoading(false);
    },
    confirmOrder() {
      this.loading = true;
      this.$emit("confirmOrder", {
        amount: this.orderAmount,
        closeLoading: () => (this.loading = false)
      });
    }
  }
};
</script>
